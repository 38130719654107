import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import MainServices from "../services/MainServices";
import axios from "axios";

const initialState = {
  setting: {},
};

const reducers = {
  UPDATE: (state, action) => {
    const setting = action.payload;
    return { ...state, setting };
  },
};

const getSettings = async (slug, fun) => {
  let settings = await axios
    .all([fun(slug)])
    .then((res) => {
      if (res[0]) {
        let data = res[0]?.data?.setting || {};
        data.digital_signature =
          data.digital_signature === "true" ? true : false;
        data.update_status =
          data.update_status === "true" ? true : false;
        return data;
      } else {
        return {};
      }
    })
    .catch((err) => {
      if (err) console.log(err);
    });

  return settings;
};

const reducer = (state, action) =>
  reducers[action.type] ? reducers[action.type](state, action) : state;

const FeatureSettingContext = createContext({
  ...initialState,
  updateSettingContext: () => Promise.resolve(),
});

FeatureSettingProvider.propTypes = {
  children: PropTypes.node,
};

function FeatureSettingProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getSettingBySlug } = MainServices();

  const updateSettingContext = async (data) => {
    const setting = await getSettings(data, getSettingBySlug);
    dispatch({ type: "UPDATE", payload: setting });
  };
  return (
    <FeatureSettingContext.Provider
      value={{
        ...state,
        updateSettingContext,
      }}
    >
      {children}
    </FeatureSettingContext.Provider>
  );
}

export { FeatureSettingContext, FeatureSettingProvider };
