import usePermission from "../../hooks/usePermission";

export default function PermissionFunctions() {
  const { permissions } = usePermission();
  const functions = {
    grantPermission: (required_permission) => {
      //return permissions.includes(required_permission);
       return true;
    },
  };
  return functions;
}
