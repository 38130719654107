import "./styles.scss";
import { InputGroup } from "react-bootstrap";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { useState } from "react";

export const PrimaryInput = (props) => {
  return <input {...props} className="input-element" />;
};

export const InputWithIcon = (props) => {
  return (
    <InputGroup className="input-group-main">
      {props.start && (
        <InputGroup.Text className="start-text">
          {props.startIcon}
        </InputGroup.Text>
      )}
      <input {...props} className="input-element" />
      {props.end && (
        <InputGroup.Text className="end-text">{props.endIcon}</InputGroup.Text>
      )}
    </InputGroup>
  );
};
export const PasswordInput = (props) => {
  const [passwordType, setPasswordType] = useState("password");

  const onTypeChange = () => {
    if (passwordType === "text") {
      setPasswordType("password");
    } else {
      setPasswordType("text");
    }
  };
  return (
    <InputGroup className="input-group-main" style={{ flexWrap: "nowrap" }}>
      <input {...props} className="input-element" type={passwordType} />
      <InputGroup.Text className="end-text">
        {passwordType === "password" ? (
          <FiEyeOff onClick={onTypeChange} />
        ) : (
          <FiEye onClick={onTypeChange} />
        )}
      </InputGroup.Text>
    </InputGroup>
  );
};
