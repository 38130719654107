import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../../assets/loaders";

const LazyComponent = lazy(() => import("./RemaindersCalender"));

export default function RemaindersCalender() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent />
    </Suspense>
  );
}
