const allRoutes = {
  // auth routes routes
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  twoStepVerification: "/two-step-verification",
  success: "/success",
  emailVerification: "/email-verification",
  lockScreen: "/lock-screen",
  resetPassword: "/reset-password",
  pageNotFound: "/page-not-found",
  dashboard: "/dashboard",
  employee: "/employee",
  contactsDetails: "/employee/details",
  contactGrid: "/employee-grid",
  documents: "/remainder",
  reports: "/reports",
  remaindersCalender: "/remainders-calender",
  remaindersRecords: "/remainders-records",
  master: "/master",
  documentType: "/remainder-type",
  documentDetails: "/remainder-type/details",
  settings: "/settings",
  department: "/department",
  departmentDetails: "/department/details",
  categoryDetails:"/categoryType/details",
  designation: "/designation",
  designationDetails: "/designation/details",
  profile: "/profile",
  categoryType: "/categoryType",
  relationType: "/relation-type",
  relationTypeDetails: "/relation-type/details",
  documentDetail: "/remainder/details",
  accessRole: "/access-role",
};

export { allRoutes };
