import React from "react";

import { Outlet } from "react-router";


const Feature = () => {
  return (
    <Outlet/>
  );
};

export default Feature;
