import React from "react";

export default function StatusCard({ stats, navigate }) {
  return (
    <div className="row">
      <div className="col-xl-6 col-lg-6">
        <div className="campaign-box bg-danger-light">
          <div className="campaign-img">
            <span>
            <i className="ti ti-brand-feedly" />
            </span>
            <p>Active</p>
          </div>

          <h2>{stats?.active || 0}</h2>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6">
        <div className="campaign-box bg-warning-light">
          <div className="campaign-img">
            <span>
            <i className="ti ti-brand-pocket" />
            </span>
            <p>In Active</p>
          </div>
          <h2>{stats?.inactive || 0}</h2>
        </div>
      </div>
    </div>
  );
}
