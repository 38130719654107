// components/PermissionRoute.js (React Higher-Order Component)

import React from "react";
import { Navigate } from "react-router-dom";
import PermissionFunctions from "../../assets/functions/PermissionFunctions";

const PermissionRoute = ({
  component: Component,
  required_permission,
  ...rest
}) => {
  const { grantPermission } = PermissionFunctions();

  return (
    <React.Fragment>
      {grantPermission(required_permission) ? (
        <Component />
      ) : (
        <Navigate to="/page-not-found" />
      )}
    </React.Fragment>
  );
};

export default PermissionRoute;
