import { Navigate, Route } from "react-router";
import { allRoutes } from "../../assets/routes/allRoutes";
import Login from "../containers/login";
import PageNotFound from "../containers/pageNotFound";
import ForgetPassword from "../containers/forgetPassword";
import PermissionRoute from "./PermissionRoute";
import Dashboard from "../containers/dashboard";
import ContactList from "../containers/contacts";
import ContactDetails from "../containers/contacts/ContactDetails";
import DocumentList from "../containers/documents";
import RemaindersCalender from "../containers/remaindersCalender";
import RemaindersRecords from "../containers/remaindersRecords";
import DocumentType from "../containers/master/documentType";
import Setting from "../containers/master/settings";
import Department from "../containers/master/department";
import DepartmentDetails from "../containers/master/department/DepartmentDetails";

import Designation from "../containers/master/designation";
import DesignationDetails from "../containers/master/designation/DesignationDetails";
import Profile from "../containers/profile";
import RelationType from "../containers/master/relationType";
import DocumentTypeDetails from "../containers/master/documentType/DocTypeDetails";
import RelationTypeDetails from "../containers/master/relationType/RelationTypeDetails";
import DocumentDetails from "../containers/documents/DocumentDetails";
import AccessRole from "../containers/master/accessRole";
import CategoryType from "../containers/master/categoryType/CategoryType";
import CategoryTypeDetails from "../containers/master/categoryType/CategoryDetails";

export const publicRoutes = [
  {
    path: allRoutes.login,
    element: <Login />,
    route: Route,
  },
  {
    path: allRoutes.pageNotFound,
    element: <PageNotFound />,
    route: Route,
  },
  {
    path: allRoutes.forgotPassword,
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/",
    name: "Root",
    element: <Navigate to="/login" />,
    route: Route,
  },
];

export const authRoutes = [
  {
    path: allRoutes.dashboard,
    element: (
      <PermissionRoute
        component={Dashboard}
        required_permission="Dashboard_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.employee,
    element: (
      <PermissionRoute
        component={ContactList}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.documents,
    element: (
      <PermissionRoute
        component={DocumentList}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.profile,
    element: (
      <PermissionRoute
        component={Profile}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.remaindersCalender,
    element: (
      <PermissionRoute
        component={RemaindersCalender}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.remaindersRecords,
    element: (
      <PermissionRoute
        component={RemaindersRecords}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.documentType,
    element: (
      <PermissionRoute
        component={DocumentType}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.settings,
    element: (
      <PermissionRoute
        component={Setting}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.department,
    element: (
      <PermissionRoute
        component={Department}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.departmentDetails,
    element: (
      <PermissionRoute
        component={DepartmentDetails}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.categoryDetails,
    element: (
      <PermissionRoute
        component={CategoryTypeDetails}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.categoryType,
    element: (
      <PermissionRoute
        component={CategoryType}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.relationType,
    element: (
      <PermissionRoute
        component={RelationType}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.documentDetails,
    element: (
      <PermissionRoute
        component={DocumentTypeDetails}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.relationTypeDetails,
    element: (
      <PermissionRoute
        component={RelationTypeDetails}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.documentDetail,
    element: (
      <PermissionRoute
        component={DocumentDetails}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.accessRole,
    element: (
      <PermissionRoute
        component={AccessRole}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.designation,
    element: (
      <PermissionRoute
        component={Designation}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.designationDetails,
    element: (
      <PermissionRoute
        component={DesignationDetails}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.contactsDetails,
    element: (
      <PermissionRoute
        component={ContactDetails}
        required_permission="Contacts_read"
      />
    ),
    route: Route,
  },
  {
    path: allRoutes.pageNotFound,
    element: <PageNotFound />,
    route: Route,
  },

  {
    path: "*",
    name: "Root",
    element: <Navigate to={allRoutes.dashboard} />,
    route: Route,
  },
];
