import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { allRoutes } from "../../../../assets/routes/allRoutes";
import CollapseHeader from "../../../../assets/common/CollapseHeader";
import MainServices from "../../../../services/MainServices";
import PageLoader from "../../../../assets/loaders/PageLoading";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import useTheme from "../../../../hooks/useTheme";
import { Formik } from "formik";
import { notifySuccess, notifyWarn } from "../../../../assets/elements/Toast";
import { Form, Button } from "react-bootstrap";
import CustomErrorMessage from "../../../../assets/elements/ErrorMessage";
import Select from "react-select";
import plusIcon from "../../../../assets/images/icons/add-icon.svg";
import minusIcon from "../../../../assets/images/icons/minus-icon.svg";
import ButtonLoading from "../../../../assets/loaders/ButtonLoading";
const DocumentTypeDetails = () => {
  const route = allRoutes;
  const [buttonLoading, setButtonLoading] = useState(0);
  const [documentType, setDocumentType] = useState({});
  const [pageLoading, setPageLoading] = useState(0);
  const { getDesignationById, updateDesignationMaster } = MainServices();
  const location = useLocation();
  const publicId = new URLSearchParams(location.search).get("publicId");
  const [selectedType, setSelectedType] = useState({});
  const [editInitiated, setEditInitiated] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [selectedDesignation, setSelectedDesignation] = useState({});
  const edit = new URLSearchParams(location.search).get("edit");

  const {
    activityTogglePopup,
    activityTogglePopupTwo,
    setActivityTogglePopup,
    setActivityTogglePopupTwo,
  } = useTheme();

  const remainderOptions = [
    { value: "daily", label: "Daily" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
  ];
  const typeOptions = [
    { value: "text", label: "Text" },
    { value: "date", label: "Date" },
    { value: "boolean", label: "Boolean" },
  ];

  const designationSchema = Yup.object().shape({
    designation_name: Yup.string()
      .transform((value) => value.trim())
      .required("Designation Name is Required"),
    designation_description: Yup.string()
      .transform((value) => value.trim())
      .required("Designation Description is Required"),
    designation_code: Yup.string()
      .transform((value) => value.trim())
      .required("Designation Code is Required"),
  });
  useEffect(() => {
    if (publicId) {
      getDocDetailsById();
    }
  }, [publicId]);

  const getDocDetailsById = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getDesignationById(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let documentTypeDetails = resData.data ? resData?.data : {};
            setDocumentType(documentTypeDetails);
            if (edit === "true" && !editInitiated) {
              onUpdatedocumentType(documentTypeDetails);
              setEditInitiated(true);
            }
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Designation Details");
      });
  };

  const updateDesignation = async (values) => {
    setButtonLoading(true);
    const details = {
      desgName: values.designation_name,
      desgDescription: values.designation_description,
      desgCode: values.designation_code,
    };

    await axios
      .all([
        updateDesignationMaster(
          details,
          selectedDesignation?.publicId,
          selectedDesignation?.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getDocDetailsById();
            setActivityTogglePopupTwo(!activityTogglePopupTwo);
            notifySuccess("Designation Updated Successfully");
            setButtonLoading(false);
          } else {
            notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in update Designation");
      });
  };

  //functions
  const onUpdatedocumentType = (selected) => {
    if (selected) {
      setSelectedDesignation(selected);
    } else {
      setSelectedDesignation("");
    }
    setActivityTogglePopupTwo(!activityTogglePopupTwo);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-10">
                    <h4 className="page-title">Designation Details</h4>
                  </div>
                  <div className="col-sm-2 text-sm-start">
                    <div className="head-icons">
                      <CollapseHeader />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {pageLoading ? (
            <PageLoader />
          ) : documentType ? (
            <div className="row">
              <div className="row handle">
                <div className="col-6">
                  <div className="contact-head">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <ul className="contact-breadcrumb">
                          <li>
                            <Link to={route.designation}>
                              <i
                                className="ti ti-arrow-narrow-left"
                                style={{ fontSize: "25px" }}
                              />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="tab-pane active show" id="activities">
                    <div className="contact-activity">
                      {documentType?.recordStatus === "active" && (
                        <div
                          className="d-flex justify-content-end"
                          to="#"
                          onClick={() => onUpdatedocumentType(documentType)}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="ti ti-edit text-blue p-1"></i> Edit
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row details-row">
                <div className="col-md-6 col-head fw-bold heading-details">
                  Name
                </div>
                <div className="col-md-6 ">{documentType?.desgName}</div>

                <div className="col-md-6 col-head fw-bold">Code</div>
                <div className="col-md-6 ">{documentType?.desgCode}</div>
                <div className="col-md-6 col-head fw-bold">Record Status</div>
                <div className="col-md-6 ">{documentType?.recordStatus}</div>
                <div className="col-md-6 col-head fw-bold">Status</div>
                <div className="col-md-6 ">{documentType?.status}</div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Edit Document */}

          {selectedType && (
            <div
              className={
                activityTogglePopupTwo
                  ? "toggle-popup1 sidebar-popup"
                  : "toggle-popup1"
              }
            >
              <div className="sidebar-layout">
                <div className="sidebar-header">
                  <h4>Update Designation</h4>
                  <Link
                    to="#"
                    className="sidebar-close1 toggle-btn"
                    onClick={() =>
                      setActivityTogglePopupTwo(!activityTogglePopupTwo)
                    }
                  >
                    <i className="ti ti-x" />
                  </Link>
                </div>
                <div className="toggle-body">
                  <div className="pro-create">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        designation_name: selectedDesignation?.desgName || "",
                        designation_code: selectedDesignation?.desgCode || "",
                        designation_description:
                          selectedDesignation?.desgDescription || "",
                      }}
                      validationSchema={designationSchema}
                      onSubmit={(values) => {
                        updateDesignation(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <Form className="form" onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-wrap">
                                <label className="col-form-label">
                                  Department Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="designation_name"
                                  id="designation_name"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.designation_name}
                                />
                                <CustomErrorMessage
                                  name="designation_name"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-wrap">
                                <label className="col-form-label">
                                  Department Code{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="designation_code"
                                  id="designation_code"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.designation_code}
                                />
                                <CustomErrorMessage
                                  name="designation_code"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-wrap mb-0">
                                <label className="col-form-label">
                                  Department Description{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  name="designation_description"
                                  id="designation_description"
                                  className="form-control"
                                  rows={5}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.designation_description}
                                />
                                <CustomErrorMessage
                                  name="designation_description"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="submit-button text-end mt-3">
                            <Link
                              to="#"
                              onClick={() =>
                                setActivityTogglePopupTwo(
                                  !activityTogglePopupTwo
                                )
                              }
                              className="btn btn-light sidebar-close"
                            >
                              Cancel
                            </Link>
                            <Button className="btn btn-primary" type="submit">
                              {buttonLoading ? (
                                <ButtonLoading />
                              ) : (
                                "Update Designation"
                              )}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* /Edit Document */}
        </div>
      </div>
    </>
  );
};

export default DocumentTypeDetails;
