import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../../../assets/loaders";

const LazyComponent = lazy(() => import("./DocumentType"));

export default function DocumentType() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent />
    </Suspense>
  );
}
