import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ImageWithBasePath from "../../assets/images/ImageWithBasicPath";
import { allRoutes } from "../../assets/routes/allRoutes";
import useTheme from "../../hooks/useTheme";
import DezenLogo from "../../assets/images/LogoHigh300011.png";

const Header = () => {
  const route = allRoutes;
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setExpandMenu,
    setMiniSidebar,
    setMobileSidebar,
    mobileSidebar,
    miniSidebar,
  } = useTheme();

  const toggleMobileSidebar = () => {
    setMobileSidebar(!mobileSidebar);
  };
  const toggleMiniSidebar = () => {
    setMiniSidebar(!miniSidebar);
  };
  const toggleExpandMenu = () => {
    setExpandMenu(true);
  };
  const toggleExpandMenu2 = () => {
    setExpandMenu(false);
  };

  const [layoutBs, setLayoutBs] = useState(localStorage.getItem("dataTheme"));
  const isLockScreen = location.pathname === "/lock-screen";

  if (isLockScreen) {
    return null;
  }
  const LayoutDark = () => {
    localStorage.setItem("dataTheme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
    setLayoutBs("dark");
  };
  const LayoutLight = () => {
    localStorage.setItem("dataTheme", "light");
    document.documentElement.setAttribute("data-theme", "light");
    setLayoutBs("light");
  };
  const onLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div
          className="header-left active"
          onMouseEnter={toggleExpandMenu}
          onMouseLeave={toggleExpandMenu2}
        >
          <Link to={route.dealsDashboard} className="logo logo-normal p-2">
            {layoutBs === "dark" ? (
              <>
                {/* <ImageWithBasePath
                  src="assets/img/white-logo.svg"
                  className="white-logo"
                  alt="Logo"
                /> */}
                <img src={DezenLogo} alt="" />
              </>
            ) : (
              // <ImageWithBasePath src="assets/img/logo.svg" alt="Logo" />
              <img src={DezenLogo} alt="" />
            )}
          </Link>
          <Link to={route.dealsDashboard} className="logo-small">
            <img src={DezenLogo} alt="" />
          </Link>
          <Link id="toggle_btn" to="#" onClick={toggleMiniSidebar}>
            <i className="ti ti-arrow-bar-to-left" />
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#sidebar"
          onClick={toggleMobileSidebar}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        <div className="header-user">
          <ul className="nav user-menu">
            {/* Search */}
            <li className="nav-item nav-search-inputs me-auto"></li>
            {/* /Search */}
            {/* Nav List */}
            {/* <li className="nav-item nav-list">
              <ul className="nav">
                <li className="dark-mode-list">
                  <Link
                    to="#"
                    className={`dark-mode-toggle ${layoutBs ? "" : "active"}`}
                    id="dark-mode-toggle"
                  >
                    <i
                      className={`ti ti-sun light-mode ${
                        layoutBs === "dark" ? "" : "active"
                      }`}
                      onClick={LayoutLight}
                    >
                      {" "}
                    </i>
                    <i
                      className={`ti ti-moon dark-mode ${
                        layoutBs === "dark" ? "active" : ""
                      }`}
                      onClick={LayoutDark}
                    ></i>
                  </Link>
                </li>
               
                
               
              </ul>
            </li> */}
            {/* /Nav List */}
            {/* Email */}

            {/* /Email */}
            {/* Notifications */}

            {/* /Notifications */}
            {/* Profile Dropdown */}
            <li className="nav-item dropdown has-arrow main-drop">
              <Link
                to="#"
                className="nav-link userset"
                data-bs-toggle="dropdown"
              >
                <span className="user-info">
                  <span className="user-letter">
                    <ImageWithBasePath
                      src="assets/img/profiles/user.png"
                      alt="Profile"
                    />
                  </span>
                </span>
              </Link>
              <div className={` dropdown-menu  menu-drop-user `}>
                <div className="profilename">
                  <Link className="dropdown-item" to={route.dealsDashboard}>
                    <i className="ti ti-layout-2" /> Dashboard
                  </Link>
                  <Link className="dropdown-item" to={route.profile}>
                    <i className="ti ti-user-pin" /> My Profile
                  </Link>
                  <span className="dropdown-item" onClick={onLogout}>
                    <i className="ti ti-lock" /> Logout
                  </span>
                </div>
              </div>
            </li>
            {/* /Profile Dropdown */}
          </ul>
        </div>
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className={` dropdown-menu `}>
            <Link className="dropdown-item" to={route.dealsDashboard}>
              <i className="ti ti-layout-2" /> Dashboard
            </Link>
            <Link className="dropdown-item" to="/profile">
              <i className="ti ti-user-pin" /> My Profile
            </Link>
            <Link className="dropdown-item" to={route.login}>
              <i className="ti ti-lock" /> Logout
            </Link>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
    </>
  );
};

export default Header;
