import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";

const initialState = {
  permissions: [],
};

const reducers = {
  UPDATE: (state, action) => {
    const permissions = action.payload;
    return { ...state, permissions };
  },
};

const reducer = (state, action) =>
  reducers[action.type] ? reducers[action.type](state, action) : state;

const PermissionContext = createContext({
  ...initialState,
  updatePermissions: () => Promise.resolve(),
});

PermissionProvider.propTypes = {
  children: PropTypes.node,
};

function PermissionProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updatePermissions = (permissions) => {
    dispatch({ type: "UPDATE", payload: permissions });
  };

  return (
    <PermissionContext.Provider
      value={{
        ...state,
        updatePermissions,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
}

export { PermissionContext, PermissionProvider };
