import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import { ConfigContext } from "../context/Config";
import useConfig from "../hooks/useConfig";

const initialState = {
  activityTogglePopup: false,
  activityTogglePopupTwo: false,
  activityTogglePopupThree: false,
  toggleAddCampaign: false,
  themeSettings: false,
  modalTitle: "",
  addTogglePopupTwo: false,
  headerCollapse: false,
  mobileSidebar: false,
  miniSidebar: false,
  expandMenu: false,
};

const reducers = {
  THEMETOGGLE: (state, action) => {
    return { ...state, theme: action.payload };
  },
  setDarkMode: (state, { payload }) => {
    return { ...state, darkMode: payload };
  },
  setActivityTogglePopup: (state, { payload }) => {
    return { ...state, activityTogglePopup: payload };
  },
  setAddTogglePopupTwo: (state, { payload }) => {
    return { ...state, activityTogglePopupTwo: payload };
  },
  setActivityTogglePopupTwo: (state, { payload }) => {
    return { ...state, activityTogglePopupTwo: payload };
  },
  setActivityTogglePopupThree: (state, { payload }) => {
    return { ...state, activityTogglePopupThree: payload };
  },
  setToggleAddCampaign: (state, { payload }) => {
    return { ...state, toggleAddCampaign: payload };
  },
  setThemeSettings: (state, { payload }) => {
    return { ...state, themeSettings: payload };
  },
  setModalTitle: (state, { payload }) => {
    return { ...state, modalTitle: payload };
  },
  setHeaderCollapse: (state, { payload }) => {
    return { ...state, headerCollapse: payload };
  },
  setMobileSidebar: (state, { payload }) => {
    return { ...state, mobileSidebar: payload };
  },
  setMiniSidebar: (state, { payload }) => {
    return { ...state, miniSidebar: payload };
  },
  setExpandMenu: (state, { payload }) => {
    return { ...state, expandMenu: payload };
  },
};

const reducer = (state, action) =>
  reducers[action.type] ? reducers[action.type](state, action) : state;

const ThemeContext = createContext({
  ...initialState,
  themeToggle: () => Promise.resolve(),
});

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

function ThemeProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const config = useConfig(ConfigContext);

  const themeToggle = (data) => {
    dispatch({ type: "THEMETOGGLE", payload: data });
  };
  const setExpandMenu = (data) => {
    dispatch({ type: "setExpandMenu", payload: data });
  };

  const setDarkMode = (data) => {
    dispatch({ type: "setDarkMode", payload: data });
  };
  const setActivityTogglePopup = (data) => {
    dispatch({ type: "setActivityTogglePopup", payload: data });
  };
  const setAddTogglePopupTwo = (data) => {
    dispatch({ type: "setAddTogglePopupTwo", payload: data });
  };
  const setActivityTogglePopupTwo = (data) => {
    dispatch({ type: "setActivityTogglePopupTwo", payload: data });
  };
  const setActivityTogglePopupThree = (data) => {
    dispatch({ type: "setActivityTogglePopupThree", payload: data });
  };
  const setToggleAddCampaign = (data) => {
    dispatch({ type: "setToggleAddCampaign", payload: data });
  };
  const setThemeSettings = (data) => {
    dispatch({ type: "setThemeSettings", payload: data });
  };
  const setModalTitle = (data) => {
    dispatch({ type: "setModalTitle", payload: data });
  };
  const setHeaderCollapse = (data) => {
    dispatch({ type: "setHeaderCollapse", payload: data });
  };
  const setMobileSidebar = (data) => {
    dispatch({ type: "setMobileSidebar", payload: data });
  };
  const setMiniSidebar = (data) => {
    dispatch({ type: "setMiniSidebar", payload: data });
  };

  return (
    <ThemeContext.Provider
      value={{
        ...state,
        theme: config.theme,
        themeToggle,
        setExpandMenu,
        setMiniSidebar,
        setMobileSidebar,
        setHeaderCollapse,
        setModalTitle,
        setActivityTogglePopupThree,
        setActivityTogglePopup,
        setActivityTogglePopupTwo,
        setAddTogglePopupTwo,
        setToggleAddCampaign,
        setThemeSettings,
        setDarkMode,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };
