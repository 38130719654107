import { useRef } from "react";

export default function TimingFunctions() {
  const interval = useRef(null);

  const functions = {
    deBounce: (callback, time) => {
      let milseconds = time ? time : 500;
      clearInterval(interval.current);
      interval.current = setTimeout(() => {
        callback();
      }, milseconds);
    },
  };
  return functions;
}
