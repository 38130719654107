import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Table from "../../../../assets/common/dataTable";
import { allRoutes } from "../../../../assets/routes/allRoutes";
import DatePicker from "react-datepicker";
import CollapseHeader from "../../../../assets/common/CollapseHeader";
import useTheme from "../../../../hooks/useTheme";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomErrorMessage from "../../../../assets/elements/ErrorMessage";
import MainServices from "../../../../services/MainServices";
import axios from "axios";
import PageLoader from "../../../../assets/loaders/PageLoading";
import { notifySuccess, notifyWarn } from "../../../../assets/elements/Toast";
import ButtonLoading from "../../../../assets/loaders/ButtonLoading";
import AsyncSelect from "react-select/async";
import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../../assets/functions/filters";
import { useLocation, useNavigate } from "react-router-dom";
import TimingFunctions from "../../../../assets/functions/TimingFunctions";
import StatusCard from "../../../../assets/elements/StatusCards";

const CategoryType = () => {
  const route = allRoutes;
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate1, setSelectedDate1] = useState(new Date());
  const {
    getRelationType,
    addCategoryType,
    getCategoryType,
    addRelationType,
    deleteCategoryTypeMaster,
    updateRelationTypeMaster,
    deleteRelationTypeMaster,
    getRelationTypeStats,
  } = MainServices();
  const [pageLoading, setPageLoading] = useState(0);
  const [relationType, setRelationType] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(0);
  const [selectedType, setSelectedType] = useState({});
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [searchString, setSearchString] = useState("");
  const [totalRelationTypes, setTotalRelationTypes] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { deBounce } = TimingFunctions();
  const [stars, setStars] = useState({});
  const [stats, setStats] = useState({});
  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);
  const [sorts, setSorts] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [activityTogglePopupTwo, setActivityTogglePopupTwo] = useState(false);
  const [categoryTypeName, setSelectedCategoryType] = useState("");

  const onCategoryTypeChange = (e) => {
    setSelectedCategoryType(e);
  };

  const categoryTypeOptions = ["Vessel", "Employee"];

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };

  const relationTypeSchema = Yup.object().shape({
    name: Yup.string()
      .transform((value) => value.trim())
      .required("Name is Required"),
    description: Yup.string()
      .transform((value) => value.trim())
      .required("Description is Required"),
  });

  const initializeStarsState = () => {
    const starsState = {};
    relationType.forEach((item, index) => {
      starsState[index] = false;
    });
    setStars(starsState);
  };

  // Call initializeStarsState once when the component mounts
  useEffect(() => {
    initializeStarsState();
  }, []);

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    let sortObj = paramsToFiltersObj(changedParams?.sort);

    setFilters(filtersObj);
    setSorts(sortObj);
    setSearchString(filtersObj?.name?.name);
    setPagination({
      page: changedParams?.page || 1,
      limit: changedParams?.limit || 20,
    });

    getRelationTypeMaster({
      page: changedParams.page || 1,
      limit: changedParams.limit || 20,
      filters,
    });

    // getModuleStatsMaster();
    // eslint-disable-next-line
  }, [changedParams.page, changedParams.limit, changedParams.filters]);

  const data = relationType;
  const handleStarToggle = (index) => {
    setStars((prevStars) => ({
      ...prevStars,
      [index]: !prevStars[index],
    }));
  };

  //apis
  const getRelationTypeMaster = async ({ page, limit, filters }) => {
    setPageLoading(true);
    const details = {
      pageSize: limit || 20,
      pageNumber: page || 1,
      filters,
      sorting: [{ key: "id", direction: "DESC" }],
    };
    await axios
      .all([getCategoryType(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let total = resData?.totalCount || 0;
            setTotalRelationTypes(total);
            let relation = resData.data.data ? resData?.data.data : [];

            relation = relation.map((_, index) => {
              return {
                ..._,
                sNo: limit * (page - 1) + index + 1,
              };
            });

            setRelationType(relation);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Relation Type");
      });
  };

  const saveRelationType = async (values) => {
    setButtonLoading(true);
    const details = {
      name: values?.categoryType,
      description: values?.description,
      status: "active",
    };

    await axios
      .all([addCategoryType(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getRelationTypeMaster({
              page: pagination.page,
              limit: pagination.limit,
            });
            // getModuleStatsMaster();
            onAddCategortype();
            notifySuccess("Category Type Added Successfully");
            setButtonLoading(false);
          } else {
            notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in adding Relation Type");
      });
  };

  const deleteRelationType = async ({ recordStatus }) => {
    setButtonLoading(true);
    const details = {
      recordStatus: recordStatus === "active" ? "inactive" : "active",
    };

    await axios
      .all([
        deleteCategoryTypeMaster(
          details,
          selectedType?.publicId,
          selectedType?.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getRelationTypeMaster({
              page: pagination.page,
              limit: pagination.limit,
            });
            // getModuleStatsMaster();
            if (selectedType?.recordStatus === "inactive") {
              notifySuccess("Category Type Activated Successfully");
            } else {
              notifySuccess("Category Type Inactivated Successfully");
            }
            setButtonLoading(false);
          } else {
            notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in delete Relation Type");
      });
  };

  // const getModuleStatsMaster = async () => {
  //   setPageLoading(true);
  //   const details = {
  //     workflowType: "category_type",
  //   };
  //   await axios
  //     .all([getRelationTypeStats(details)])
  //     .then(function (res) {
  //       if (res[0]) {
  //         let resData = res[0];
  //         if (resData.success) {
  //           const productStats = resData?.data?.recordStatusStats
  //             ? resData?.data?.recordStatusStats
  //             : {};
  //           const stats = productStats ? productStats : [];
  //           setStats(stats);
  //         } else {
  //         }
  //       } else {
  //       }
  //     })
  //     .catch(function (res) {
  //       console.log(res);
  //       if (res.message) console.log("An error occurred in getting Stats");
  //     });
  // };

  //functions
  const onAddCategortype = (selected) => {
    if (selected) {
      setSelectedType(selected);
    } else {
      setSelectedType("");
    }
    setActivityTogglePopupTwo(!activityTogglePopupTwo);
  };

  const onDeleteRelationType = (selected) => {
    if (selected) {
      setSelectedType(selected);
    } else {
      setSelectedType("");
    }
  };

  const searchRelationType = (e) => {
    setSearchLoading(true);
    deBounce(() => onSearchFilter(e));
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      name: {
        name: e.target.value,
      },
    });
    let msorts = filtersToUri(sorts);
    let search = filtersToUrlParams({
      page: 1,
      limit: 10,
      filters: mfilters,
      sort: msorts,
    });
    navigate({
      search,
    });
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      render: (text, record, index) => (
        <div
          className={`set-star rating-select ${stars[index] ? "filled" : ""}`}
          onClick={() => handleStarToggle(index)}
          key={index}
        >
          <i className="fa fa-star"></i>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record, index) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <div
            onClick={() =>
              navigate(`/categoryType/details?publicId=${record.publicId}`)
            }
            className="profile-split d-flex flex-column"
            style={{ cursor: "pointer" }}
          >
            {record.name}
          </div>
        </div>
      ),
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text, record, index) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          {record.description}
        </div>
      ),
      // sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "Status",
      dataIndex: "recordStatus",
      render: (text, record, index) => (
        <div key={index}>
          <span
            className={`badge badge-pill badge-status ${
              record?.recordStatus === "inactive" ? "bg-danger" : "bg-success"
            }`}
          >
            {record?.recordStatus === "inactive"
              ? record?.recordStatus
              : record?.status === "approved"
              ? record?.recordStatus
              : record?.status}
          </span>
        </div>
      ),
      // sorter: (a, b) => a.owner.length - b.owner.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record, index) => (
        <div className="dropdown table-action" key={index}>
          <Link
            to="#"
            className="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i className="fa fa-ellipsis-v"></i>
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              position: "absolute",
              inset: "0px auto auto 0px",
              margin: "0px",
              transform: "translate3d(-99.3333px, 35.3333px, 0px)",
            }}
            data-popper-placement="bottom-start"
          >
            {record?.recordStatus === "active" && (
              <div
                onClick={() =>
                  navigate(
                    `/categoryType/details?publicId=${record.publicId}&edit=true`
                  )
                }
                style={{ cursor: "pointer", display: "flex" }}
              >
                <i className="ti ti-edit text-blue p-1"></i> Edit
              </div>
            )}
            {record?.recordStatus === "active" && (
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete_contact"
                onClick={() => onDeleteRelationType(record)}
              >
                <i className="ti ti-trash text-danger"></i> Inactive
              </Link>
            )}
            {record?.recordStatus === "inactive" && (
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete_contact"
                onClick={() => onDeleteRelationType(record)}
              >
                <i className="ti ti-check text-success"></i> Active
              </Link>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-10">
                    <h4 className="page-title">
                      Category Type
                      <span className="count-title">
                        {pageLoading ? "" : `(${totalRelationTypes || 0})`}
                      </span>
                    </h4>
                  </div>
                  <div className="col-2 text-start">
                    <div className="head-icons">
                      <CollapseHeader />
                    </div>
                  </div>
                </div>
                {/* <div>
                  <StatusCard stats={stats} navigate={navigate} />
                </div> */}
              </div>
              {/* /Page Header */}
              <div className="card main-card">
                <div className="card-body">
                  {/* Search */}
                  <div className="search-section">
                    <div className="row">
                      <div className="col-md-5 col-sm-4">
                        <div className="form-wrap icon-form">
                          <span className="form-icon">
                            <i className="ti ti-search" />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Category Type Name"
                            onChange={(e) => searchRelationType(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-7 col-sm-8">
                        <div className="export-list text-sm-end">
                          <ul>
                            {/* <li>
                              <div className="export-dropdwon">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                >
                                  <i className="ti ti-package-export" />
                                  Export
                                </Link>
                                <div className="dropdown-menu  dropdown-menu-end">
                                  <ul>
                                    <li>
                                      <Link to="#">
                                        <i className="ti ti-file-type-pdf text-danger" />
                                        Export as PDF
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="#">
                                        <i className="ti ti-file-type-xls text-green" />
                                        Export as Excel{" "}
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li> */}
                            <li>
                              <div
                                className="btn btn-primary add-popup"
                                onClick={() => onAddCategortype()}
                                style={{ cursor: "pointer" }}
                              >
                                <i className="ti ti-square-rounded-plus" />
                                Add Category Type
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Search */}
                  {/* Filter */}

                  {/* /Filter */}
                  {/* relation type List */}

                  <div className="table-responsive custom-table">
                    {pageLoading ? (
                      <PageLoader />
                    ) : relationType && relationType.length > 0 ? (
                      <Table
                        columns={columns}
                        dataSource={relationType}
                        rowKey="id"
                        pagination={{
                          total: totalRelationTypes,
                          pageSize: 10,
                          onChange: (page, pageSize) =>
                            getRelationTypeMaster({
                              page,
                              limit: pageSize,
                              filters: {},
                            }),
                        }}
                      />
                    ) : (
                      <div className="no-data-found-main">
                        No Relation Type Found
                      </div>
                    )}
                  </div>

                  {/* <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="datatable-length" />
                    </div>
                    <div className="col-md-6">
                      <div className="datatable-paginate" />
                    </div>
                  </div> */}

                  {/* /Contact List */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Add Contact */}
      <div
        className={
          activityTogglePopupTwo ? "toggle-popup sidebar-popup" : "toggle-popup"
        }
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>Add Category Type</h4>
            <div
              className="sidebar-close toggle-btn"
              onClick={() => setActivityTogglePopupTwo(!activityTogglePopupTwo)}
              style={{ cursor: "pointer" }}
            >
              <i className="ti ti-x" />
            </div>
          </div>
          <div className="toggle-body">
            <div className="pro-create">
              {activityTogglePopupTwo && (
                <Formik
                  initialValues={{
                    categoryType: "",
                    description: "",
                  }}
                  //   validationSchema={relationTypeSchema}
                  onSubmit={(values) => {
                    saveRelationType(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form className="form" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label
                          className="col-form-label"
                          htmlFor="categoryType"
                        >
                          Category Type
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="categoryType"
                          name="categoryType"
                          value={values.categoryType}
                          onChange={(e) =>
                            setFieldValue("categoryType", e.target.value)
                          }
                          onBlur={handleBlur}
                          required
                        />
                        <CustomErrorMessage
                          name="categoryType"
                          errors={errors}
                          touched={touched}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label className="col-form-label" htmlFor="description">
                          Description
                          <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          rows={5}
                          placeholder="Enter Description"
                          aria-label="With textarea"
                          name="description"
                          id="description"
                          value={values.description}
                          onChange={handleChange}
                          required
                        ></textarea>
                        <CustomErrorMessage
                          name="description"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                      <div className="submit-button text-end mt-3">
                        <Link
                          to="#"
                          onClick={() =>
                            setActivityTogglePopupTwo(!activityTogglePopupTwo)
                          }
                          className="btn btn-light sidebar-close"
                        >
                          Cancel
                        </Link>
                        <Button className="btn btn-primary" type="submit">
                          {buttonLoading ? <ButtonLoading /> : "Create"}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* /Add Relation Type*/}
      {/* Edit Relation Type */}

      {/* /Edit Relation Type */}

      {/* Delete Relation Type */}
      {selectedType && (
        <div
          className="modal custom-modal fade"
          id="delete_contact"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 m-0 justify-content-end">
                <button
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <div className="modal-body">
                <div className="success-message text-center">
                  <div className="success-popup-icon">
                    {selectedType?.recordStatus === "inactive" ? (
                      <i className="ti ti-check text-success" />
                    ) : (
                      selectedType?.recordStatus === "active" && (
                        <i className="ti ti-trash text-danger" />
                      )
                    )}
                  </div>

                  {selectedType?.recordStatus === "active" ? (
                    <>
                      <h3>Inactive Category Type?</h3>
                      <p className="del-info">
                        Are you sure you want to Inactive?{" "}
                        <span
                          style={{
                            color: "#9d72ff",
                            textDecoration: "underline",
                          }}
                        >
                          {selectedType?.name}
                        </span>
                        .
                      </p>
                    </>
                  ) : (
                    <p className="del-info">
                      Do you want to change the status to Active?{" "}
                      <span
                        style={{
                          color: "#9d72ff",
                          textDecoration: "underline",
                        }}
                      >
                        {selectedType?.name}
                      </span>
                      .
                    </p>
                  )}
                  <div className="col-lg-12 text-center modal-btn">
                    <Link
                      to="#"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        deleteRelationType({
                          recordStatus: selectedType?.recordStatus,
                        });
                      }}
                    >
                      {buttonLoading ? (
                        <ButtonLoading />
                      ) : selectedType?.recordStatus === "active" ? (
                        "Yes, Inactive it"
                      ) : (
                        "Submit"
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* /Delete Contact */}
    </div>
  );
};

export default CategoryType;
