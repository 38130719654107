import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";


const initialState = {
    currentPage: "Dashboard",
};

const reducers = {
    UPDATE: (state, action) => {
        const currentPage = action.payload;
        return { ...state, currentPage };
    },

};

const reducer = (state, action) =>
    reducers[action.type] ? reducers[action.type](state, action) : state;

const CurrentPageContext = createContext({
    ...initialState,
    updateCurrentPage: () => Promise.resolve(),
});

CurrentPageProvider.propTypes = {
    children: PropTypes.node,
};

function CurrentPageProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const updateCurrentPage = (data) => {
        dispatch({ type: "UPDATE", payload: data });
    };
    return (
        <CurrentPageContext.Provider
            value={{
                ...state,
                updateCurrentPage,
            }}
        >
            {children}
        </CurrentPageContext.Provider>
    );
}

export { CurrentPageContext, CurrentPageProvider };
