import React from "react";
import Header from "./ThemeHeader";
import Sidebar from "./ThemeSidebar";
import useTheme from "../../hooks/useTheme";

const AuthFeature = (props) => {
  const { headerCollapse, mobileSidebar, miniSidebar, expandMenu } = useTheme();
  return (
    <div
      className={`${miniSidebar ? "mini-sidebar" : ""} ${
        expandMenu ? "expand-menu" : ""
      }`}
    >
      <div
        className={`main-wrapper ${headerCollapse ? "header-collapse" : ""} ${
          mobileSidebar ? "slide-nav" : ""
        }`}
      >
        <Header />
        <Sidebar />
        {props.children}
      </div>
      <div className="sidebar-overlay"></div>
    </div>
  );
};

export default AuthFeature;
