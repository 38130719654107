import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useConfig from "../../hooks/useConfig";

import * as Storage from "../../store/LocalStorage";
import { LazyLoader } from "../../assets/loaders";

import axios from "axios";
import MainServices from "../../services/MainServices";
import usePermission from "../../hooks/usePermission";
import { notifySuccess, notifyWarn } from "../../assets/elements/Toast";
import ResetPassword from "../containers/resetPassword/ResetPassword";
import { authRoutes, publicRoutes } from "./RouterLinks";
import Feature from "../layouts/Layout";
import AuthFeature from "../layouts/AuthLayout";
import { allRoutes } from '../../assets/routes/allRoutes';

import Profile from "../containers/profile/Profile";


export default function CustomRoutes() {
  const { user, updateUserContext } = useAuth();
  const { updatePermissions } = usePermission();
  const [loading, setLoading] = useState(false);
  const config = useConfig();
  const navigate = useNavigate();
  const { getRolePermissionsByRoles, getUserDetails } = MainServices();
  const location = useLocation();

  const token = new URLSearchParams(location.search).get("token");

  useEffect(() => {
    if (token) {
      getUser(token);
    } else {
      checkSession();
    }
    // eslint-disable-next-line
  }, []);

  const getUser = async () => {
    setLoading(true);
    const details = {};
    await axios
      .all([getUserDetails(details, token)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let userDetails = resData?.data || {};
            userDetails = {
              token,
              email: userDetails?.empEmail,
              name: userDetails?.empName,
              role: userDetails?.role,
              user_id: userDetails?.publicId,
              empFirstLoginStatus: userDetails?.empFirstLoginStatus,
            };
            Storage.set(config.sessionKey, JSON.stringify(userDetails));
            updateUserContext(userDetails);
            getRolePermissions(token);
            navigate("/dashboard");
            notifySuccess("User Login Successfully");
            setLoading(true);
          } else {
            notifyWarn(message);
            setLoading(true);
          }
        } else {
          setLoading(true);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in User Signin");
      });
  };

  const checkSession = () => {
    setLoading(true);
    let session = Storage.get(config.sessionKey);
    if (session) {
      session = JSON.parse(session);
      // getRolePermissions(session.token);
      updateUserContext(session);
      setLoading(false);
    } else {
      navigate({ pathname: "/login", search: location.search });
      setLoading(false);
    }
  };

  //api
  const getRolePermissions = async (token) => {
    const details = {
      source: "auth",
    };
    await axios
      .all([getRolePermissionsByRoles(details, token)])
      .then(async function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let rolePermissions = resData.data ? resData.data : [];
            rolePermissions = rolePermissions.map(
              (each) => each.permissionname
            );
            await updatePermissions(rolePermissions);
            setLoading(false);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Permissions");
      });
  };

  return loading ? (
    <LazyLoader />
  ) : user?.token ? (
    user?.empFirstLoginStatus === "false" ? (
      <AuthFeature>

        <Routes >
          {authRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Routes>
        </AuthFeature>
    
    ) : (
      <Routes>
        <Route path="*" element={<ResetPassword />} />
      </Routes>
    )
  ) : (
    <Routes>
      <Route element={<Feature />} path={"/"}>
        {publicRoutes.map((route, idx) => (
          <Route path={route.path} element={route.element} key={idx} />
        ))}
      </Route>
      <Route path={allRoutes.profile} element={<Profile/>}/>
     
    </Routes>
  );
}
