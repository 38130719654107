import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";

//css
import "../node_modules/bootstrap/dist/css/bootstrap.rtl.min.css";
import "../src/style/css/feather.css";
import "../src/index.scss";
import "../src/style/icon/boxicons/boxicons/css/boxicons.min.css";

import "../src/style/icon/weather/weathericons.css";
import "../src/style/icon/typicons/typicons.css";
import "../src/style/icon/fontawesome/css/fontawesome.min.css";
import "../src/style/icon/fontawesome/css/all.min.css";
import "../src/style/icon/ionic/ionicons.css";
import "../src/style/icon/tabler-icons/webfont/tabler-icons.css";

import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";


//Bootstarp

const root = ReactDOM.createRoot(
    document.getElementById("crm")
  );
  localStorage.setItem("dataTheme", "light");
  localStorage.setItem("dataColor", "red");
  localStorage.setItem("dataSidebar", "light");
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
