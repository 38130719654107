import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { allRoutes } from "../../../../assets/routes/allRoutes";
import CollapseHeader from "../../../../assets/common/CollapseHeader";
import MainServices from "../../../../services/MainServices";
import PageLoader from "../../../../assets/loaders/PageLoading";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { notifySuccess, notifyWarn } from "../../../../assets/elements/Toast";
import { Form, Button } from "react-bootstrap";
import CustomErrorMessage from "../../../../assets/elements/ErrorMessage";
import ButtonLoading from "../../../../assets/loaders/ButtonLoading";

const CategoryTypeDetails = () => {
  const route = allRoutes;
  const [buttonLoading, setButtonLoading] = useState(0);
  const [pageLoading, setPageLoading] = useState(0);
  const { getCategoryType, updateCategoryType } = MainServices();
  const location = useLocation();
  const publicId = new URLSearchParams(location.search).get("publicId");
  const [selectedType, setRelationType] = useState({});
  const [editInitiated, setEditInitiated] = useState(false);
  const edit = new URLSearchParams(location.search).get("edit");
  const [relationModel, setRelationModel] = useState(false);

  const relationTypeSchema = Yup.object().shape({
    categoryType: Yup.string()
      .transform((value) => value.trim())
      .required("Category Type is Required"),
    description: Yup.string()
      .transform((value) => value.trim())
      .required("Description is Required"),
  });

  useEffect(() => {
    if (publicId) {
      getRelationTypeDetails();
    }
  }, [publicId]);

  const getRelationTypeDetails = async () => {
    setPageLoading(true);
    const details = { filters: [{ key: "publicId", eq: publicId }] };
    await axios
      .all([getCategoryType(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let relationTypeData = resData.data.data ? resData?.data.data : {};
            setRelationType(relationTypeData);
            if (edit === "true" && !editInitiated) {
              onUpdatedocumentType(relationTypeData);
              setEditInitiated(true);
            }
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Relation Type Details");
      });
  };

  const updateRelationType = async (values) => {
    setButtonLoading(true);
    const details = {
      name: values?.categoryType,
      description: values?.description,
    };

    await axios
      .all([
        updateCategoryType(
          details,
          selectedType[0]?.publicId,
          selectedType[0]?.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getRelationTypeDetails();
            setRelationModel(false);
            notifySuccess("Relation Type Updated Successfully");
            setButtonLoading(false);
          } else {
            notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in update Relation Type");
      });
  };

  //functions
  const onUpdatedocumentType = (selected) => {
    if (selected) {
      setRelationType(selected);
    }
    setRelationModel(!relationModel);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-10">
                    <h4 className="page-title">Category Details</h4>
                  </div>
                  <div className="col-sm-2 text-sm-start">
                    <div className="head-icons">
                      <CollapseHeader />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {pageLoading ? (
            <PageLoader />
          ) : selectedType ? (
            <div className="row">
              <div className="row handle" style={{ padding: "5px 20px" }}>
                <div className="col-6">
                  <div className="contact-head">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <ul className="contact-breadcrumb">
                          <li>
                            <Link to={route.categoryType}>
                              <i
                                className="ti ti-arrow-narrow-left"
                                style={{ fontSize: "25px" }}
                              />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {selectedType[0]?.recordStatus === "active" && (
                  <div
                    className="d-flex justify-content-end"
                    to="#"
                    onClick={() => onUpdatedocumentType(selectedType)}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ti ti-edit text-blue p-1"></i> Edit
                  </div>
                )}
              </div>

              <div className="col-xl-12">
                <div className="row details-row">
                  <div className="col-md-6 col-head fw-bold heading-details">
                    Name
                  </div>
                  <div className="col-md-6 ">{selectedType[0]?.name}</div>

                  <div className="col-md-6 col-head fw-bold">Description</div>
                  <div className="col-md-6 ">
                    {selectedType[0]?.description}
                  </div>

                  <div className="col-md-6 col-head fw-bold">Status</div>
                  <div className="col-md-6 ">{selectedType[0]?.status}</div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Edit Document */}

          {selectedType && (
            <div
              className={
                relationModel ? "toggle-popup1 sidebar-popup" : "toggle-popup1"
              }
            >
              <div className="sidebar-layout">
                <div className="sidebar-header">
                  <h4>Update Category Type</h4>
                  <span
                    className="sidebar-close1 toggle-btn"
                    onClick={() => setRelationModel(false)}
                  >
                    <i className="ti ti-x" />
                  </span>
                </div>
                <div className="toggle-body">
                  <div className="pro-create">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        categoryType: selectedType[0]?.name || "",
                        description: selectedType[0]?.description || "",
                      }}
                      validationSchema={relationTypeSchema}
                      onSubmit={(values) => {
                        updateRelationType(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <Form className="form" onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label
                              className="col-form-label"
                              htmlFor="categoryType"
                            >
                              Category Type
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              id="categoryType"
                              name="categoryType"
                              value={values.categoryType}
                              onChange={(e) =>
                                setFieldValue("categoryType", e.target.value)
                              }
                              onBlur={handleBlur}
                              required
                            />
                            <CustomErrorMessage
                              name="categoryType"
                              errors={errors}
                              touched={touched}
                            />
                          </div>

                          <div className="form-group mt-3">
                            <label
                              className="col-form-label"
                              htmlFor="description"
                            >
                              Description
                              <span className="text-danger">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              rows={5}
                              placeholder="Enter Description"
                              aria-label="With textarea"
                              name="description"
                              id="description"
                              value={values.description}
                              onChange={handleChange}
                            ></textarea>
                            <CustomErrorMessage
                              name="description"
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className="submit-button text-end mt-3">
                            <Button
                              className="btn btn-light sidebar-close"
                              onClick={() => setRelationModel(false)}
                            >
                              Cancel
                            </Button>
                            <Button className="btn btn-primary" type="submit">
                              {buttonLoading ? (
                                <ButtonLoading />
                              ) : (
                                "Save Changes"
                              )}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* /Edit Document */}
        </div>
      </div>
    </>
  );
};

export default CategoryTypeDetails;
