import { Button } from "react-bootstrap";
import "./Buttons.scss";
import useConfig from "../../hooks/useConfig";

export const PrimaryButton = (props) => {
  const {theme} = useConfig();
  return (
    <Button
      {...props}
      className={`tms-primary-btn  ${theme} ${
        props.className || props.class ? props.class || props.className : ""
      }`}
    >
      {props.title}
    </Button>
  );
};
